import Waves from './Waves';

export const Header = (props) => {
  return (
    <header id='header'>
      <div className='intro'>
        <Waves />
        </div>
    </header>
  )
}
