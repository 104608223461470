import React from "react";

class Waves extends React.Component {
  render() {
    return (
      <div>
        {/*Hey! This is the original version
of Simple CSS Waves*/}
        <div className="header">
          {/*Content before waves*/}
          <div className="inner-header">
            {/*Just the logo.. Don't mind this*/}
            <h1>we're (just) tiny now.</h1>
            <p>we've got a brand new site and brand new image. join us!</p>
            <a href="https://www.wearetiny.io/">
            <button type="button" class="default-button">
              Click Me
            </button>
          </a>            
          </div>        
          {/*Waves Container*/}
          <div>
            <svg
              className="waves"
              xmlns="http://www.w3.org/2000/svg"
              xmlnsXlink="http://www.w3.org/1999/xlink"
              viewBox="0 24 150 28"
              preserveAspectRatio="none"
              shapeRendering="auto"
            >
              <defs>
                <path
                  id="gentle-wave"
                  d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z"
                />
              </defs>
              <g className="parallax">
                <use
                  xlinkHref="#gentle-wave"
                  x={48}
                  y={0}
                  fill="rgba(255,255,255,0.7"
                />
                <use
                  xlinkHref="#gentle-wave"
                  x={48}
                  y={3}
                  fill="rgba(255,255,255,0.5)"
                />
                <use
                  xlinkHref="#gentle-wave"
                  x={48}
                  y={5}
                  fill="rgba(255,255,255,0.3)"
                />
                <use xlinkHref="#gentle-wave" x={48} y={7} fill="#fff" />
              </g>
            </svg>
          </div>
          {/*Waves end*/}
        </div>
        {/*Header ends*/}
      </div>
    );
  }
}

export default Waves;
